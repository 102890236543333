<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card>
        <b-form-group
          label="Başlık"
          label-for="title"
        >
          <validation-provider
            #default="{ errors }"
            name="Başlık"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="dataItem.title"
              placeholder="Başlık"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Alt Başlık"
          label-for="subtitle"
        >
          <b-form-input
            id="subtitle"
            v-model="dataItem.subtitle"
            placeholder="Alt Başlık"
          />
        </b-form-group>
        <b-button
          v-if="dataItem.file"
          variant="primary"
          :href="baseUrl + dataItem.file"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Dosya İndir
        </b-button>
        <hr>
        <b-button
          variant="danger"
          @click="submitForm"
        >
          <FeatherIcon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-card>
    </validation-observer>
    <b-card>
      <template v-if="!fileData.uploading">
        <b-form-group
          label="Dosya Yükle"
          label-for="file-upload"
        >
          <b-form-file
            id="file-upload"
            v-model="fileData.file"
            placeholder="Dosya Yükle"
            drop-placeholder="Dosyayı buraya sürükleyin"
            browse-text="Dosya Seçin"
          />
        </b-form-group>
        <b-button
          v-if="fileData.file"
          variant="success"
          @click="uploadFile"
        >
          <FeatherIcon icon="UploadIcon" />
          Dosya Yükle
        </b-button>
      </template>
      <template v-else>
        <b-alert
          variant="info"
          show
        >
          <div class="alert-body text-center p-2">
            <div>
              <b-spinner type="grow" />
            </div>
            <div class="mt-2">
              Dosya Yükleniyor...
            </div>
          </div>
        </b-alert>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BFormInput, BFormFile, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'IndexPage',
  components: {
    ValidationObserver,
    ValidationProvider,
    BCard,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BAlert,
    BSpinner,
  },
  data() {
    return {
      required,
      baseUrl: `${this.$store.state.app.baseURL}/media/portal/documents/`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['organizationCharts/dataItem']
    },
    fileData() {
      return this.$store.getters['organizationCharts/fileData']
    },
    saveData() {
      return this.$store.getters['organizationCharts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.getData()
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('organizationCharts/getData')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('organizationCharts/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
    uploadFile() {
      this.$store.dispatch('organizationCharts/uploadFile', {
        upload_file: this.fileData.file,
      })
    },
  },
}
</script>
